button, hr, input {
  overflow: visible;
}

progress, sub, sup {
  vertical-align: baseline;
}

[type=checkbox], [type=radio], legend {
  box-sizing: border-box;
  padding: 0;
}

html {
  line-height: 1.15;
}

body {
  margin: 0;
}

details, main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, select {
  text-transform: none;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=search] {
  outline-offset: -2px;
}

summary {
  display: list-item;
}

[hidden], template {
  display: none;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Inter-Black.woff2") format("woff2"), url("../fonts/Inter-Black.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat"), url("../fonts/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter-SemiBold.woff") format("woff");
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

body {
  min-width: 320px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: normal;
  font-weight: normal;
  color: #FFFFFF;
  background: #090F1E;
}

p, h1, h2, h3, h4 {
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.reset {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.container {
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1220px;
  text-align: center;
}

.padding {
  padding-top: 50px;
  padding-bottom: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: flex-start;
  text-align: left;
}
.list picture {
  margin: auto;
}

.center {
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.item {
  display: flex;
  flex-direction: column;
  max-width: 560px;
}
.item picture {
  margin: auto;
}

.title,
.subtitle {
  margin-bottom: 20px;
  font-size: 42px;
  font-weight: 600;
}

.subtitle {
  font-size: 32px;
}

.text {
  font-weight: 500;
}

.text-margin {
  margin-bottom: 20px;
  font-weight: 500;
}

.btn {
  display: inline-block;
  border: none;
  border-radius: 8px;
  margin: auto;
  padding: 24px 40px;
  max-width: 163px;
  font-weight: 700;
  color: #FFFFFF;
  background: -webkit-gradient(linear, left top, right top, from(#8856E9), to(#05B3FE));
  background: -o-linear-gradient(left, #8856E9 0%, #05B3FE 100%);
  background: linear-gradient(90deg, #8856E9 0%, #05B3FE 100%);
}

.btn:focus {
  outline: none;
}

.subtext {
  font-size: 14px;
  font-weight: 500;
  color: #A4ADC1;
}

.num-div {
  counter-reset: num;
}

.number {
  position: relative;
  max-width: 360px;
}

.number:before {
  content: counter(num) ".";
  counter-increment: num;
  display: inline-block;
  position: absolute;
  border-radius: 5px;
  text-align: center;
  left: 0;
  width: 30px;
  height: 30px;
  padding: 7px;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  background: -o-linear-gradient(315deg, #1AEAFD 0%, #0A64C9 100%);
  background: linear-gradient(135deg, #1AEAFD 0%, #0A64C9 100%);
}

.li {
  margin-left: 40px;
}

.point-div {
  margin-left: 18px;
  counter-reset: num;
}

.point {
  position: relative;
  font-size: 14px;
}

.point:not(:last-child) {
  margin-bottom: 8px;
}

.point:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4.5px;
  left: -18px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  font-size: 14px;
  background: #0A64C9;
}

.table {
  border-radius: 10px;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  overflow: hidden;
  margin-bottom: 40px;
  font-size: 14px;
}
.table thead th:first-child,
.table tbody tr th {
  display: none;
}
.table th,
.table td {
  text-align: center;
  width: 112px;
  padding: 9px 10px;
}
.table th {
  font-weight: 700;
  background: #06B3FE;
}
.table td {
  padding: 19px 10px;
  font-weight: 600;
  background: #1E263D;
}

.banner,
.subbanner {
  border-radius: 20px;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 760px;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #A4ADC1;
  background: -webkit-gradient(linear, left top, right top, from(#182135), to(#0E1525));
  background: -o-linear-gradient(left, #182135 0%, #0E1525 100%);
  background: linear-gradient(90deg, #182135 0%, #0E1525 100%);
}

.subbanner {
  text-align: center;
  color: #FFFFFF;
}

.none {
  display: none;
}

@media (max-width: 1000px) {
  .list {
    flex-direction: column;
    align-items: center;
  }
  .item,
  .text {
    max-width: 100%;
  }
  .text {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  body {
    font-size: 14px;
    background: #1D222F;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 320px;
  }
  .padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .title {
    font-size: 32px;
  }
  .subtitle {
    font-size: 24px;
  }
  .btn {
    padding: 16px 24px;
  }
  .table {
    margin-bottom: 20px;
    font-size: 10px;
  }
  .table th,
  .table td {
    width: 60px;
  }
  .table td {
    padding: 7px 4px;
  }
  .block {
    display: none;
  }
  .none {
    display: block;
  }
}
/* header */
.header {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #141B2F;
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1440px;
}
.header__logo {
  flex-shrink: 0;
}
.header__cube {
  display: flex;
  justify-content: space-between;
  width: 75.7%;
}
.header__margin-link:not(:last-child) {
  margin-right: 20px;
}
.header__link {
  font-size: 16px;
  font-weight: 600;
}
.header__border {
  display: none;
}
.header__burger {
  display: none;
  flex-shrink: 0;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
}
.header__btn {
  margin: 0;
  max-width: 163px;
  color: #4D4728;
  background: -webkit-gradient(linear, left top, right top, from(#FFE962), to(#FFBC08));
  background: -o-linear-gradient(left, #FFE962 0%, #FFBC08 100%);
  background: linear-gradient(90deg, #FFE962 0%, #FFBC08 100%);
  white-space: nowrap;
}

@keyframes menu-open {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes menu-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@media (max-width: 1200px) {
  .header__burger {
    display: block;
  }
  .header__cube {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 5;
    right: 0;
    top: 80px;
    display: none;
    margin: auto;
    padding: 10px 20px;
    width: 152px;
    background: #192338;
  }
  .header__cube.is-opened {
    display: flex;
    animation-name: menu-open;
    animation-duration: 0.5s;
  }
  .header__cube.is-closed {
    animation-name: menu-close;
    animation-duration: 0.5s;
  }
  .header__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 0.1px solid #FFE000;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .header__margin-link:not(:last-child) {
    margin: 0;
    margin-bottom: 7px;
  }
  .header__link {
    font-size: 16px;
    font-weight: 700;
  }
  .header__btn {
    margin-bottom: 16px;
    padding: 16px 24px;
    font-size: 10px;
    color: #FFFFFF;
    background: #21B05E;
  }
  .header__border {
    display: block;
    border-bottom: 0.1px solid #FFE000;
    width: 112px;
  }
}
@media (max-width: 576px) {
  .header {
    padding-top: 15px;
    padding-bottom: 10px;
    background: #192338;
  }
  .header__container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header__logo {
    width: 79.5px;
    height: 30px;
  }
  .header__cube {
    top: 45px;
  }
}
/* download */
.download {
  padding-top: 100px;
}
.download__subtext {
  max-width: 360px;
}
.download__btn {
  margin-bottom: 100px;
}

@media (max-width: 576px) {
  .download {
    padding-top: 40px;
  }
  .download__list {
    margin-bottom: 20px;
  }
  .download__btn {
    margin: 0;
  }
}
/* apk */
.apk__list {
  margin-bottom: 77px;
}
.apk__title {
  margin-bottom: 18px;
  max-width: 550px;
}
.apk__subtext {
  margin-top: 18px;
}

@media (max-width: 576px) {
  .apk__list {
    margin-bottom: 20px;
  }
}
/* file */
.file__item-text {
  margin-bottom: 20px;
}
.file__text {
  margin-bottom: 40px;
}
.file__subtext {
  margin: auto;
  margin-bottom: 40px;
  max-width: 760px;
}
.file__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 40px;
  margin-bottom: 38px;
}
.file__item:last-child {
  text-align: center;
  padding-top: 170px;
}
.file__item-text {
  text-align: left;
  max-width: 320px;
  font-family: "Inter";
  line-height: 22px;
}

@media (max-width: 576px) {
  .file__item:last-child {
    text-align: center;
    padding-top: 0;
  }
}
/* betting */
.betting__list {
  margin: 0;
}
.betting__text {
  margin-bottom: 40px;
}
.betting__item picture {
  padding-top: 10px;
}
.betting__subtext {
  margin-top: 10px;
}

@media (max-width: 576px) {
  .betting__table th,
  .betting__table td {
    padding: 4px;
  }
  .betting__subtext {
    margin-bottom: 10px;
    font-size: 10px;
  }
}
/* support */
.support__div {
  align-items: flex-start;
  margin: 0;
}
.support__subtext {
  margin-top: 10px;
}
.support__text {
  margin-bottom: 20px;
}
.support__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.support__item {
  max-width: 260px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.support__btn {
  margin-left: 48.5px;
  margin-right: 48.5px;
}

@media (max-width: 576px) {
  .support__list {
    margin-bottom: 20px;
  }
  .support__item {
    max-width: 130px;
    font-size: 10px;
  }
  .support__subtext {
    margin-bottom: 20px;
    font-size: 10px;
  }
  .support__subbtn {
    text-align: center;
  }
}
/* update */
.update__list {
  margin-bottom: 37px;
}
.update__list picture {
  padding-left: 37px;
}
.update__text {
  margin-bottom: 20px;
}

/* win */
.win__list {
  margin-bottom: 40px;
}
.win__list picture {
  padding-left: 37px;
}
.win__subtext {
  margin: 0;
  max-width: 760px;
}
.win__ol {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 40px;
}
.win__item {
  max-width: 360px;
}
.win__text {
  margin-bottom: 20px;
  max-width: 320px;
}
.win__banner {
  text-align: left;
}

/* system */
.system__list {
  margin: 0;
}
.system__text {
  margin-bottom: 40px;
}
.system__subtext {
  margin-top: 7px;
}
.system__btn {
  margin-left: 0;
}

@media (max-width: 576px) {
  .system__table th {
    padding: 4px;
  }
  .system__subtext {
    margin-bottom: 20px;
    font-size: 10px;
  }
  .system__center {
    text-align: center;
  }
}
/* devices */
.devices__div {
  margin: 0;
}
.devices__subtext {
  margin-top: 8px;
  margin-bottom: 8px;
}
.devices__text {
  margin-bottom: 20px;
}
.devices__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  column-gap: 40px;
}
.devices__item {
  margin-right: 40px;
  max-width: 110px;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 576px) {
  .devices__list {
    gap: 10px;
  }
  .devices__item {
    margin: 0;
    max-width: 67.5px;
  }
}
/* how */
.how__list picture {
  padding-left: 40px;
}
.how__text {
  max-width: 560px;
}
.how__btn {
  margin-bottom: 97px;
}

/* windows */
.windows__text-new {
  margin: 0;
  max-width: 560px;
}
.windows__text {
  max-width: 760px;
  margin: auto;
}
.windows__list, .windows__ol {
  text-align: left;
  margin-bottom: 40px;
}
.windows__list picture {
  padding-left: 40px;
}
.windows__ol {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 44px;
}
.windows__item {
  max-width: 560px;
  font-family: "Inter";
}
.windows__banner {
  text-align: center;
  font-size: 18px;
}

@media (max-width: 576px) {
  .windows__list picture {
    padding-left: 0;
  }
  .windows__ol {
    margin-bottom: 20px;
    gap: 20px;
  }
  .windows__subtext {
    margin-bottom: 0;
  }
  .windows__banner {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
/* client */
.client__list {
  margin-bottom: 40px;
}
.client__list picture {
  padding-left: 40px;
}
.client__text {
  margin-bottom: 20px;
}
.client__subtext {
  margin: 0;
}
.client__btn {
  margin-bottom: 100px;
}

@media (max-width: 576px) {
  .client__list {
    margin-bottom: 20px;
  }
  .client__list picture {
    padding-left: 0;
  }
  .client__subtext {
    margin-bottom: 20px;
  }
}
/* review */
.review__subtext {
  margin-bottom: 20px;
}
.review__list {
  margin-bottom: 40px;
  margin-left: 18px;
}
.review__item, .review__elem {
  position: relative;
  font-size: 14px;
}
.review__item:not(:last-child), .review__elem:not(:last-child) {
  margin-bottom: 10px;
}
.review__item:before, .review__item:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 14px;
  left: -18px;
  width: 16px;
  height: 2.4px;
  background: #4B79EB;
}
.review__item:before {
  transform: rotate(90deg);
}
.review__ul {
  margin-left: 18px;
}
.review__elem:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 14px;
  left: -18px;
  width: 16px;
  height: 2.4px;
  background: #6D7382;
}
.review__text {
  text-align: left;
  margin-left: 8px;
  max-width: 534px;
}
.review__flex {
  margin-bottom: 40px;
}
.review__div {
  text-align: left;
  max-width: 560px;
}

@media (max-width: 576px) {
  .review__list {
    margin-bottom: 20px;
  }
  .review__flex {
    flex-direction: column;
  }
  .review__text {
    font-size: 10px;
  }
  .review__div:not(:last-child) {
    margin-bottom: 0;
  }
}
/* bet */
.bet__subtext {
  margin: auto;
  max-width: 760px;
}
.bet__list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 40px;
}
.bet__item {
  max-width: 560px;
}
.bet__text {
  margin-bottom: 20px;
}

/* profile */
.profile__new-text {
  text-align: justify;
  margin: auto;
  max-width: 760px;
}
.profile__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 40px;
}
.profile__item {
  max-width: 360px;
}
.profile__text {
  text-align: left;
  margin-bottom: 40px;
}
.profile__text span {
  font-size: 14px;
  color: #959BAA;
}
.profile__subtext {
  margin-bottom: 40px;
}

@media (max-width: 576px) {
  .profile__new-text {
    text-align: center;
  }
}
/* logo */
.log__new-text {
  margin: auto;
  max-width: 760px;
}
.log__list {
  align-items: normal;
}
.log__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 494px;
}
.log__text {
  margin-bottom: 20px;
}
.log__flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.log__subtext {
  text-align: left;
  max-width: 560px;
}

@media (max-width: 576px) {
  .log__title {
    font-size: 24px;
  }
  .log__item {
    height: 100%;
  }
}
/* money */
.money__new-text {
  margin: auto;
  max-width: 760px;
}
.money__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}
.money__item {
  max-width: 560px;
}
.money__text {
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .money__list {
    margin: 0;
  }
}
/* make */
.make__title {
  margin-top: 75px;
}
.make__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}
.make__item {
  border-left: 2px solid #0A64C9;
  padding-left: 10px;
}
.make__flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.make__text {
  text-align: left;
  max-width: 548px;
}

@media (max-width: 576px) {
  .make__photo {
    padding-top: 20px;
  }
  .make__list {
    gap: 10px;
    margin-bottom: 20px;
  }
  .make__text {
    margin: 0;
  }
  .make__flex {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .make__subtext {
    margin-bottom: 10px;
  }
}
/* sport */
.sport__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}
.sport__item {
  max-width: 560px;
}
.sport__text {
  text-align: left;
  max-width: 520px;
}

@media (max-width: 576px) {
  .sport__list {
    gap: 10px;
    margin-bottom: 20px;
  }
}
/* poker */
.poker__title {
  margin-top: 75px;
}
.poker__subtext {
  margin-bottom: 20px;
}
.poker__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  padding-bottom: 40px;
}
.poker__item {
  border-left: 2px solid #0A64C9;
  padding-left: 10px;
}
.poker__text {
  text-align: left;
  max-width: 548px;
}

@media (max-width: 576px) {
  .poker__title, .poker__text {
    margin: 0;
  }
  .poker__subtext {
    margin-bottom: 20px;
  }
  .poker__list {
    gap: 20px;
    padding-bottom: 20px;
  }
}
/* without */
.without__flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.without__text {
  text-align: left;
  max-width: 560px;
}

@media (max-width: 576px) {
  .without__flex {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
/* mobile */
.mobile__text {
  margin-bottom: 40px;
}
.mobile__table thead tr th {
  display: none;
}
.mobile__table tbody tr th {
  display: block;
}
.mobile__table tr {
  display: flex;
}
.mobile__table tr:not(:last-child) {
  border-bottom: 2px solid #FFF;
}
.mobile__table th, .mobile__table td {
  text-align: left;
  padding: 19px 20px;
  width: 580px;
}
.mobile__table th {
  font-size: 24px;
  font-weight: 700;
  padding: 37px 20px;
}
.mobile__table td {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .mobile__table th, .mobile__table td {
    text-align: left;
    padding: 19px 20px;
    width: 100%;
    font-size: 14px;
  }
  .mobile__table td {
    font-size: 12px;
  }
}
/* promo */
.promo__list {
  margin-bottom: 80px;
}
.promo__text {
  margin-bottom: 40px;
}

/* welcome */
.welcome__list {
  margin-bottom: 80px;
}

/* cashback */
.cashback__title {
  margin-bottom: 40px;
}
.cashback__table th,
.cashback__table td {
  width: 386px;
  font-size: 18px;
  font-weight: 600;
}
.cashback__table th {
  padding: 6px 20px;
  font-weight: 700;
}
.cashback__table td {
  padding: 17px 20px;
}

@media (max-width: 576px) {
  .cashback__table th,
  .cashback__table td {
    font-size: 12px;
  }
}
/* bonus */
.bonus__list {
  margin-bottom: 20px;
}
.bonus__text {
  margin-bottom: 111px;
}
.bonus__table {
  margin: 0;
}
.bonus__table thead tr th {
  display: none;
}
.bonus__table tbody tr th {
  display: block;
  width: 117px;
  padding: 15px 10px;
}
.bonus__table tbody tr td {
  padding: 15px 10px;
  font-weight: 700;
}
.bonus__subtext {
  margin-top: 20px;
}

@media (max-width: 576px) {
  .bonus__text {
    margin-bottom: 20px;
  }
  .bonus__photo {
    padding-bottom: 20px;
  }
  .bonus__table {
    border-radius: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
    overflow: hidden;
    font-size: 14px;
    font-weight: 700;
  }
  .bonus__table thead th:first-child,
  .bonus__table tbody tr th {
    display: none;
  }
  .bonus__table th,
  .bonus__table td {
    text-align: center;
    width: 150px;
    padding: 9px 10px;
  }
  .bonus__table tr:first-child td {
    background: #06B3FE;
  }
  .bonus__table td {
    padding: 19px 10px;
    background: #1E263D;
  }
}
/* victory */
.victory__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  text-align: left;
  margin-bottom: 40px;
}
.victory__item {
  border-left: 2px solid #0A64C9;
  padding: 0;
  padding-left: 10px;
  width: 260px;
}
.victory__banner {
  max-width: 960px;
  font-size: 18px;
}

@media (max-width: 576px) {
  .victory__list {
    gap: 20px;
    margin-bottom: 20px;
  }
  .victory__banner {
    font-size: 14px;
  }
}
/* deposit */
.deposit__text, .deposit__list {
  margin-bottom: 40px;
}
.deposit__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}
.deposit__banner {
  max-width: 960px;
  font-size: 18px;
}

@media (max-width: 576px) {
  .deposit__ul {
    padding-bottom: 20px;
  }
  .deposit__item {
    font-size: 10px;
  }
  .deposit__banner {
    font-size: 14px;
  }
}
/* service */
.service__banner {
  max-width: 960px;
  font-size: 18px;
}

@media (max-width: 576px) {
  .service__item {
    margin-bottom: 20px;
  }
  .service__banner {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
/* faq */
.faq {
  padding-bottom: 100px;
}
.faq__title {
  text-align: center;
  margin-bottom: 40px;
}
.faq__accordion {
  position: relative;
  text-transform: none;
  text-align: left;
  border-radius: 8px;
  margin: 0;
  padding: 30px;
  padding-right: 59px;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  background: -webkit-gradient(linear, left top, right top, from(#182135), to(#0E1525));
  background: -o-linear-gradient(left, #182135 0%, #0E1525 100%);
  background: linear-gradient(90deg, #182135 0%, #0E1525 100%);
  cursor: pointer;
}
.faq__accordion:not(:last-child) {
  margin-bottom: 10px;
}
.faq__accordion::before {
  content: "";
  position: absolute;
  right: 33px;
  top: 30px;
  border: none;
  border-right: 3px solid #FFFFFF;
  border-bottom: 3px solid #FFFFFF;
  width: 18px;
  height: 18px;
  pointer-events: none;
  transform: rotate(45deg);
  transition: 0.3s transform;
}
.faq__text {
  margin: 0;
  max-height: 0;
  text-align: left;
  overflow: hidden;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  background: -webkit-gradient(linear, left top, right top, from(#182135), to(#0E1525));
  background: -o-linear-gradient(left, #182135 0%, #0E1525 100%);
  background: linear-gradient(90deg, #182135 0%, #0E1525 100%);
}
.faq__accordion.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #FFFFFF;
  margin: 0;
}
.faq__accordion.active + .faq__text {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 10px;
  padding: 30px;
}
.faq__accordion.active::before {
  transform: translate(0, 7px) rotate(225deg);
}

@media (max-width: 576px) {
  .faq__accordion {
    padding: 16px;
    padding-right: 59px;
    font-size: 18px;
  }
  .faq__accordion::before {
    top: 40%;
  }
}
/* footer */
.footer {
  padding-top: 60px;
  padding-bottom: 100px;
  font-size: 14px;
  color: #FFFFFF;
}
.footer__flex {
  justify-content: space-between;
  align-items: center;
}
.footer__new {
  display: flex;
}
.footer__item {
  display: flex;
  align-items: flex-start;
}
.footer__item:not(:last-child) {
  margin-bottom: 30px;
}
.footer__item:last-child {
  justify-content: flex-start;
}
.footer__subcube {
  display: flex;
  flex-direction: row;
}
.footer__elem {
  text-align: left;
}
.footer__elem:not(:last-child) {
  margin-right: 51px;
}
.footer__subtitle {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
}
.footer__subtext {
  margin: 0;
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #A4ADC1;
}
.footer__tel {
  font-weight: 600;
}
.footer__tel img {
  margin-right: 10px;
}
.footer__subitem {
  display: flex;
  flex-direction: column;
}
.footer__subitem:not(:last-child) {
  margin-right: 22px;
}
.footer__title {
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 700;
  color: #A4ADC1;
}
.footer__link {
  font-size: 10px;
  font-weight: 600;
}
.footer__app-div {
  display: flex;
  flex-direction: column;
  margin-top: 57px;
}
.footer__text {
  margin: 0;
  font-size: 10px;
  color: #A4ADC1;
}
.footer__text:not(:last-child), .footer__app:not(:last-child), .footer__link:not(:last-child) {
  margin-bottom: 10px;
}
.footer__icon:not(:last-child) {
  margin-right: 6px;
}
.footer__photo:not(:last-child) {
  margin-right: 10px;
}
.footer__img:not(:last-child) {
  margin-right: 40px;
}

@media (max-width: 1000px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #090F1E;
  }
  .footer__item, .footer__new {
    flex-direction: column;
  }
  .footer__flex {
    flex-direction: column;
  }
  .footer__subelem {
    padding-right: 55px;
  }
  .footer__subnew {
    flex-direction: row;
  }
  .footer__elem:not(:last-child) {
    margin: 0;
    margin-bottom: 20px;
  }
  .footer__div {
    flex-direction: row;
  }
  .footer__title {
    margin-bottom: 10px;
  }
  .footer__cube {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  .footer__app-div {
    flex-direction: row;
    margin-top: 20px;
  }
  .footer__app {
    margin: 0;
  }
  .footer__app:not(:last-child) {
    margin-right: 20px;
  }
  .footer__container {
    display: flex;
    align-items: center;
  }
  .footer__icons {
    margin-bottom: 40px;
  }
  .footer .footer__img:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}